const en = {
    translation: {
        AccountSetup: {
            DefaultAccountSetupStepGraphicAlt: 'Onboarding step image',
            EmbeddedVideoNotSupported: 'Sorry, your browser does not support embedded videos.',
        },
        Forms: {
            Labels: {
                Back: 'BACK',
                Next: 'NEXT',
                Skip: 'SKIP',
                Steps: '{{completedSteps}} of {{totalSteps}}',
            },
        },
        NoInformationProvided: 'No information provided.',
        WebstoreSections: {
            About: {
                Title: 'the basics',
                MarketsServed: 'markets served',
                StoreLocation: 'store location',
                OnlineOnly: 'Online Only',
                TravelRadius: 'travel radius',
                ShippingRadius: 'shipping radius',
                PriceTier: 'price tier',
                ReadMore: 'Read More',
                ShowLess: 'Show Less',
                Miles: 'miles',
                Capacity: 'capacity',
                Guests: 'guests',
            },
            AmenitiesServices: {
                Title: 'services we offer',
            },
            AvailabilityCalendar: {
                Title: 'Availability',
                Description: 'Share your availability to help brides secure their perfect date!',
                Subtitle:
                    'Use this section to let potential customers see when your services will not be available.',
                Available: 'Available',
                PartiallyAvailable: 'Partially Available',
                Unavailable: 'Unavailable',
                Availability: 'availability',
                AvailabilityCalendar: 'Availability  Calendar',
                EditAvailability: 'Edit Availability',
                DatesSelected: 'Dates Selected',
                BackToCalendar: 'back to availability calendar',
                AriaLabels: {
                    NextButton: 'Next',
                    PreviousButton: 'Previous',
                },
            },
            Awards: {
                Title: 'Awards and Certificates',
                Description: 'Work with other vendors in our network? Link their storefronts here!',
            },
            Faqs: {
                Title: 'FAQs',
                ViewMore: 'view more',
                ViewLess: 'view less',
                Description:
                    'Answer your most common questions so couples have all the info they need!',
            },
            Gallery: {
                Title: 'Media',
                Description: 'Show off your work with your best photos!',
            },
            Media: {
                SeeAllMediaButtonLabel: 'See All Photos',
                SeeLessButtonLabel: 'See Less',
                NoMediaUploaded: 'You have not uploaded any photos yet',
            },
            Packages: {
                Title: 'Packages',
                Description: 'Offer service packages or tiered pricing? Let couples know!',
                PriceLabel: 'price',
                InclusionsLabel: 'inclusions',
                DetailsButtonLabel: 'see full details',
            },
            PreferredPartners: {
                Title: 'Business Besties',
                DescriptionHeader: 'why we love this vendor',
                ViewVendorButton: 'view this vendor',
                Description: 'Work with other vendors in our network? Link their storefronts here!',
            },
            PriceTier: {
                BudgetFriendly: 'budget-friendly',
                Standard: 'standard',
                Premium: 'premium',
                Luxury: 'luxury',
            },
            PlanTier: {
                Starter: 'Starter',
                Essential: 'Essential',
                Premium: 'Premium',
            },
            Reviews: {
                Title: 'google reviews',
                SectionHeader: 'what customers are saying',
                SeeAllButton: 'see all reviews',
                SingleReviewText: 'review',
                MultipleReviewsText: 'reviews',
                AddressCopy:
                    "Your Google business profile must have an address associated with it or reviews may not display on Pearl by David's",
            },
            BusinessCategory: {
                SelectCategory: 'business category',
            },
        },
        Navigation: {
            AriaLabel: 'Go to',
            WebstoreNavigation: {
                AboutLinkDisplayName: 'about',
                AvailabilityLinkDisplayName: 'availability',
                ReviewsLinkDisplayName: 'reviews',
                MediaGalleryLinkDisplayName: 'photos',
                PackagesLinkDisplayName: 'packages',
                FaqsLinkDisplayName: 'FAQS',
                PartnersLinkDisplayName: 'besties',
                AwardsLinkDisplayName: 'awards',
            },
        },
        Alert: {
            UpdatePromptPrimary: 'We need more information!',
            UpdatePromptSecondary:
                'We noticed you changed your Vendor status. Please update your Vendor Webstore with specific details on capacity or service characteristics.',
            UpdateBtnPrimary: 'Got it!',
        },
        FlaggedImages: {
            Header: 'image flagged',
            Text: `This image does not meet the standards of our image guidelines.`,
            Contact: 'CONTACT ADMINISTRATOR',
        },
        Messaging: {
            SendAMessage: 'send a message...',
            AvatarAlt: 'participant avatar',
            MessageTypes: {
                Text: 'text',
                Image: 'image',
                Attachment: 'attachment',
                QuoteRequest: 'new inquiry',
            },
            Quotes: {
                QuoteRequest: {
                    Title: 'new inquiry',
                    MessageToVendor: 'Message to Vendor',
                    Name: 'Name',
                    EventDate: 'Event Date',
                    Budget: 'Budget',
                    EventType: 'Event Type',
                },
            },
            EmptyInbox: {
                Text: 'no messages yet',
                Subtext: 'your inbox is empty',
            },
            ReportToPearl: 'report to Pearl Team',
            Reporting: {
                OtherMessage: 'please provide details',
                ReportCustomer: 'why do you wish to report this customer?',
                ReportVendor: 'why do you wish to report this vendor?',
                OffensiveMessage: 'Offensive message (rude, obscene, harassment)',
                Spam: 'Spam (Ads, self-promotion)',
                Fraud: 'Fraud',
                Other: 'Other',
                Submit: 'submit',
                SubmitError: 'Error submitting',
            },
        },
        SocialLinks: {
            WebsiteLabel: 'website url',
            YouTubeLabel: 'youtube',
            GoogleLabel: 'google',
            PinterestLabel: 'pinterest',
            YelpLabel: 'yelp',
            TwitterLabel: 'twitter',
            FacebookLabel: 'facebook',
            TikTokLabel: 'tik tok',
            InstagramLabel: 'instagram',
            VimeoLabel: 'vimeo',
            SpotifyLabel: 'spotify',
            SoundCloudLabel: 'soundcloud',
            XLabel: 'X (formerly known as twitter)',
        },
        PearlModalContext: {
            CloseAriaLabel: 'close',
        },
    },
};

export { en };
