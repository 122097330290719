/* eslint-disable eqeqeq */
import styled from 'styled-components';
import { billingOptions } from 'vendor/constants/select-options/quantity';
import { Dropdown } from 'dbi-pearl-ui-kit';
import { useFormContext } from 'react-hook-form';
import { PearlError } from 'framework';
import { formatPrice } from 'framework/constants/WebstorePlanTiers';
import { usePromoCode } from '../Checkout/PromoCodeContext';
const OverviewLabel = styled.p`
     {
        font-size: 14px;
        margin: 0px;
        @media (max-width: 815px) {
            font-size: 12px;
        }
    }
`;
const OverviewTitle = styled.h2`
     {
        font-size: 16px;
        text-transform: capitalize;
        line-height: 22.4px;
        margin: 0px;
    }
`;
const CheckoutOverViewSectionStyled = styled.div`
    max-width: 432px;
    display: flex;
    @media (max-width: 815px) {
        max-width: 100% !important;
    }
    .checkoutDetails {
        display: flex;
        width: 100%;
        @media (max-width: 815px) {
            width: 100% !important;
            gap: 24px;
        }
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 32px;

        @media (min-width: 815px) {
            border-left: 1px solid #cfd0d0;
            padding-left: 24px;
        }
    }
    #Billing {
        border-radius: 1000px;
        font-weight: 700 !important;
        font-size: 14px;
        padding-right: 30px;
    }
    .Billing-container {
        width: 184px;
        .p4 {
            display: none;
        }
    }
    .summaryTitle {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding-bottom: 16px;
        border-bottom: 1px solid #cfd0d0;
        @media (max-width: 815px) {
            gap: 16px;
            padding-bottom: 8px;
        }
    }
    .billing {
        display: flex;
        align-items: center;
        gap: 12px;
        width: 202px;
        span {
            font-size: 14px;
        }
    }
    .middleSection {
        display: flex;
        padding-bottom: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
        border-bottom: 1px solid #cfd0d0;
    }
    .subtotal {
        display: flex;
        padding-bottom: 16px;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;

        & > p {
            margin: 0px;
        }
    }
    .paymentDisclaimer {
        & > p {
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
            margin: 0px;
        }
    }
    .priceContainer {
        display: flex;
        padding-bottom: 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
        border-bottom: 1px solid #cfd0d0;
    }
`;

const CheckoutOverviewSection = ({
    title,
    planValue,
    subtotalTitle,
    totalTitle,
    savingsTitle,
    subscriptionSubtitle,
    paymentDisclaimer,
    salesTax,
    taxLabel,
}) => {
    const { getValues } = useFormContext();
    const defaultBilling = { label: 'Billed Annually', value: 12 };
    const formBillingFrequency = Number(getValues('Billing'));
    const { promoCodeDiscount } = usePromoCode();
    const calculateSubtotal = (planValue) => {
        if (formBillingFrequency === 1) {
            const subtotalDisplayVal = parseFloat(formBillingFrequency * planValue);
            return subtotalDisplayVal.toFixed(2);
        } else {
            const subtotalDisplayVal = parseFloat(
                formBillingFrequency * Math.floor(planValue * 0.8)
            );
            return subtotalDisplayVal.toFixed(2);
        }
    };
    const calculateTotal = (planValue, salesTax) => {
        let subtotalPrice = calculateSubtotal(planValue);
        const floatTax = parseFloat(salesTax);
        const discount = promoCodeDiscount ? subtotalPrice * (promoCodeDiscount / 100) : null;
        const totalWithTax = parseFloat(subtotalPrice + floatTax - discount).toFixed(2);
        return totalWithTax;
    };
    const calculateSavings = (planValue) => {
        if (formBillingFrequency === 12) {
            const discount = promoCodeDiscount
                ? parseFloat(formBillingFrequency * Math.floor(planValue * 0.8)) *
                  (promoCodeDiscount / 100)
                : null;
            return discount.toFixed(2);
        }
        if (formBillingFrequency === 1) {
            const discount = promoCodeDiscount
                ? parseFloat(formBillingFrequency * planValue) * (promoCodeDiscount / 100)
                : null;
            return discount.toFixed(2);
        }
    };

    return (
        <CheckoutOverViewSectionStyled>
            <div className="checkoutDetails">
                <div className="summaryTitle">
                    <OverviewTitle>{title}</OverviewTitle>
                    <h2
                        style={{
                            fontSize: '40px',
                            textTransform: 'uppercase',
                            lineHeight: '48px',
                            margin: '0px',
                        }}
                    >
                        US${formatPrice(calculateSubtotal(planValue))}{' '}
                        <span
                            style={{
                                fontSize: '16px',
                                textTransform: 'lowercase',
                                lineHeight: '22.4px',
                            }}
                        >
                            {formBillingFrequency == 1 ? '/mo*' : '/yr*'}
                        </span>
                    </h2>
                </div>
                <div className="middleSection">
                    <OverviewLabel>{subscriptionSubtitle}</OverviewLabel>
                    <div className="billing">
                        <Dropdown
                            formFieldName="Billing"
                            id="Billing"
                            providedLabel={` `}
                            placeholder={defaultBilling.label}
                            defaultValue={defaultBilling.value}
                            style={{ fontFamily: 'Objektiv Mk2 Bold' }}
                        >
                            <option key={defaultBilling.label} value={defaultBilling.value}>
                                {defaultBilling.label}
                            </option>
                            <option key={billingOptions.value} value={billingOptions.value}>
                                {billingOptions.label}
                            </option>
                        </Dropdown>
                    </div>
                    <PearlError name="Billing" />
                </div>
                <div className="priceContainer">
                    <div className="subtotal" style={{ borderBottom: '1px solid #cfd0d0' }}>
                        <OverviewLabel>{subtotalTitle}</OverviewLabel>
                        <p style={{ fontFamily: 'Objektiv Mk2 Bold', fontSize: '14px' }}>
                            ${calculateSubtotal(planValue)}
                        </p>
                    </div>
                    {promoCodeDiscount && (
                        <div className="subtotal" style={{ borderBottom: '1px solid #cfd0d0' }}>
                            <OverviewLabel>{savingsTitle}</OverviewLabel>
                            <p style={{ fontFamily: 'Objektiv Mk2 Bold', fontSize: '14px' }}>
                                -${calculateSavings(planValue)}
                            </p>
                        </div>
                    )}
                    <div className="subtotal" style={{ borderBottom: '1px solid #cfd0d0' }}>
                        <OverviewLabel>{taxLabel}</OverviewLabel>
                        <p style={{ fontFamily: 'Objektiv Mk2 Bold', fontSize: '14px' }}>
                            ${parseFloat(salesTax).toFixed(2)}
                        </p>
                    </div>
                    <div className="subtotal" style={{ paddingBottom: '0px' }}>
                        <OverviewLabel>{totalTitle}</OverviewLabel>
                        <p
                            data-testid={'totalSum'}
                            style={{ fontFamily: 'Objektiv Mk2 Bold', fontSize: '18px' }}
                        >
                            ${calculateTotal(planValue, salesTax)}
                        </p>
                    </div>
                    {formBillingFrequency === 12 && (
                        <div className="paymentDisclaimer" data-testid={'paymentDisclaimer'}>
                            <p style={{ fontFamily: 'Objektiv Mk2 Bold' }}> {paymentDisclaimer}</p>
                        </div>
                    )}
                </div>
            </div>
        </CheckoutOverViewSectionStyled>
    );
};

export { CheckoutOverviewSection };
