import React from 'react';
import { TextInput } from 'dbi-pearl-ui-kit';
import { useEffect, useState } from 'react';
import { FormRow, PearlError } from 'framework';
import {
    BasicsEditFormStyled,
    VisitPearlPageButton,
    VisitPearlPageButtonContainer,
} from './BasicsEditForm.styled';
import { SectionEditHeader } from '../SectionEditHeader/SectionEditHeader';
import { useStore } from 'stores/Store';
import { Capacitor, Plugins } from '@capacitor/core';
import { openWindow } from 'vendor/lib/WindowService';
import { useTranslation } from 'react-i18next';
import { VendorCategoryMultiSelect } from 'vendor/components/VendorCategoryMultiSelect';
import { VendorSubcategoryMultiSelect } from 'vendor/components/VendorSubcategoryMultiSelect';
import FormRowStyled from 'framework/components/forms/FormRow/FormRow.styled';

import { WebstoreService } from 'vendor/lib/apis/WebstoreService';

const { Browser } = Plugins;

const BasicsEditForm = (props) => {
    const { displayUrlInfo } = props;
    const { appData, webstore } = useStore();
    const { t } = useTranslation();
    const webstoreApi = WebstoreService();
    const { categoryList } = appData;

    const visitPearlPageButtonHandler = (e) => {
        e.preventDefault();
        const webstorePreviewUrl = process.env.REACT_APP_BASE_URL + '/' + webstore.WebstoreUrl;
        if (Capacitor.isNativePlatform()) {
            Browser.open({ url: webstorePreviewUrl });
        } else {
            openWindow(webstorePreviewUrl);
        }
    };
    const Columns = () => [
        <div key={0}>
            <TextInput
                formFieldName="WebstoreUrl"
                data-testid="webstore-url"
                disabled={!webstore.HasBeenPublished && !webstore.WebstoreUrl}
                providedLabel="Forms.Labels.PearlWebstoreUrl"
                preText={`${window.location.origin.replace(/^http(s?):\/\//i, '')}/`}
            />
            <PearlError name={'WebstoreUrl'} />
        </div>,
        <VisitPearlPageButtonContainer key={1}>
            <VisitPearlPageButton
                onClick={visitPearlPageButtonHandler}
                data-testid="preview-webstore-button"
                disabled={!webstore.HasBeenPublished && !webstore.WebstoreUrl}
            >
                {t('VisitWebstore')}
            </VisitPearlPageButton>
        </VisitPearlPageButtonContainer>,
    ];

    const [categoryListItems] = useState(
        categoryList.map((x) => {
            return {
                id: x.id,
                label: x.name,
                imageUrl: x.image?.url,
                subCategories: x.subCategories.map((y) => ({
                    id: y.id,
                    label: y.name,
                    imageUrl: null,
                    categoryId: x.id,
                })),
            };
        })
    );

    const [selectedCategoryIds, setSelectedCategoryIds] = useState(
        webstore.VendorCategoryId
            ? webstore.VendorCategoryId.split(',').map((item) => parseFloat(item.trim()))
            : []
    );
    const [selectedCategoryDetail, setSelectedCategoryDetail] = useState(
        categoryListItems?.filter((item) => selectedCategoryIds?.includes(item.id)) || []
    );

    useEffect(() => {
        const updateTravels = async () => {
            console.log('Current webstore:', webstore);
            console.log('Travels value:', webstore?.Travels);
            console.log('WebstoreApi:', webstoreApi);

            if (webstore?.Travels === false) {
                try {
                    console.log('Attempting to update Travels to true');
                    webstore.updateWebstore({ Travels: true });
                    const patchFunction = webstoreApi.patchWebstore;
                    const patch = await patchFunction(webstore.forApi);
                    if (patch.ok) {
                        console.log('success');
                    } else {
                        console.log('error');
                    }
                } catch (error) {
                    console.error('Error updating Travels:', error);
                }
            }
        };

        updateTravels();
    }, [webstore]);

    return (
        <BasicsEditFormStyled>
            <SectionEditHeader
                title="WebstoreSections.VendorBasics.Title"
                description="WebstoreSections.VendorBasics.Subtitle"
            />
            <FormRow
                columns={[
                    <div key={0}>
                        <TextInput
                            formFieldName="WebstoreName"
                            providedLabel="Forms.EditBasics.BusinessNameLabel"
                        />
                        <PearlError name={'WebstoreName'} />
                    </div>,
                    <div key={1}>
                        <VendorCategoryMultiSelect
                            categoryListItems={categoryListItems}
                            selectedCategoryIds={selectedCategoryIds}
                            setSelectedCategoryIds={setSelectedCategoryIds}
                            selectedCategoryDetail={selectedCategoryDetail}
                            setSelectedCategoryDetail={setSelectedCategoryDetail}
                        />
                        <PearlError name={'VendorCategoryId'} />
                    </div>,
                ]}
            />
            {!!displayUrlInfo && (
                <FormRowStyled>
                    <Columns />
                </FormRowStyled>
            )}

            {selectedCategoryDetail && (
                <FormRow
                    columns={[
                        <div key={0}>
                            <VendorSubcategoryMultiSelect
                                categoryListItems={categoryListItems}
                                selectedCategoryIds={selectedCategoryIds}
                                setSelectedCategoryIds={setSelectedCategoryIds}
                                selectedCategoryDetail={selectedCategoryDetail}
                                setSelectedCategoryDetail={setSelectedCategoryDetail}
                            />
                        </div>,
                    ]}
                ></FormRow>
            )}
        </BasicsEditFormStyled>
    );
};

export { BasicsEditForm };
