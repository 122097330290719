import { PearlError } from 'framework';
import { SectionEditHeader } from '../SectionEditHeader/SectionEditHeader';
import { SocialLinksSectionEditStyled } from './SocialLinksSectionEdit.styled';
import { SocialLinksSection } from 'vendor/components/Webstore/SocialLinksSection/SocialLinksSection';

const SocialLinksSectionEdit = () => {
    const labels = {
        Website: 'Forms.SocialLinks.WebsiteLabel',
        YouTube: 'Forms.SocialLinks.YouTubeLabel',
        Google: 'Forms.SocialLinks.GoogleLabel',
        Pinterest: 'Forms.SocialLinks.PinterestLabel',
        Yelp: 'Forms.SocialLinks.YelpLabel',
        Twitter: 'Forms.SocialLinks.XLabel',
        Facebook: 'Forms.SocialLinks.FacebookLabel',
        TikTok: 'Forms.SocialLinks.TikTokLabel',
        Instagram: 'Forms.SocialLinks.InstagramLabel',
        Vimeo: 'Forms.SocialLinks.VimeoLabel',
        Spotify: 'Forms.SocialLinks.SpotifyLabel',
        SoundCloud: 'Forms.SocialLinks.SoundCloudLabel',
    };

    return (
        <SocialLinksSectionEditStyled data-testid="social-links-section-edit">
            <SectionEditHeader
                title="Forms.SocialLinks.StepName"
                description="Forms.SocialLinks.EditSectionDescription"
            />
            <PearlError name="SocialLinks" />
            <SocialLinksSection labels={labels} />
        </SocialLinksSectionEditStyled>
    );
};

export { SocialLinksSectionEdit };
