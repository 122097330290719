import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { vendorBusinessSummaryFormSchema } from 'vendor/constants/schema/VendorBusinessSummaryFormSchema';
import { BasicsEditForm } from 'vendor/components/Webstore/SectionEdit/BasicsEditForm/BasicsEditForm';
import { BusinessSummarySectionEdit } from 'vendor/components/Webstore/SectionEdit/BusinessSummarySectionEdit/BusinessSummarySectionEdit';
import { mergeSchemas } from 'vendor/components/Webstore/WebstoreHelpers/WebstoreHelpers';
import { EditSectionLayout } from '../EditSectionLayout/EditSectionLayout';
import { PriceTierSectionEdit } from 'vendor/components/Webstore/SectionEdit/PriceTierSectionEdit/PriceTierSectionEdit';
import PearlForm, { FormType } from 'vendor/components/forms/PearlForm';
import { useOutletContext } from 'react-router-dom';
import { SocialLinksSectionEdit } from 'vendor/components/Webstore/SectionEdit/SocialLinksSectionEdit/SocialLinksSectionEdit';
import { ServiceAreaSectionEdit } from 'vendor/components/Webstore/SectionEdit/ServiceAreaSectionEdit/ServiceAreaSectionEdit';
import { serviceAreaFormSchema } from 'vendor/components/Webstore/ServiceAreaForm/ServiceAreaFormSchema';
import { vendorCapacityFormSchema } from 'vendor/constants/schema/VendorCapacityFormSchema';
import { EditAboutPageStyled } from './EditAboutPage.styled';
import { CapacitySectionEdit } from 'vendor/components/Webstore/SectionEdit/CapacitySectionEdit/CapacitySectionEdit';
import { WebstoreSectionTypes } from 'framework';
import { getFormDefaults } from 'vendor/components/Webstore/ServiceAreaForm/Functions/ServiceAreaForm.Functions';
import { WebstoreAddressService } from 'framework';
import { useStore } from 'stores/Store';
import { webstoreUrlSchemaWrapper } from 'vendor/constants/schema/WebstoreUrlSchema';
import { vendorBusinessDetailsFormSchemaWrapper } from 'vendor/constants/schema/VendorBusinessDetailsFormSchema';
import {
    formFieldNames,
    formatBusinessLocationForPatch,
    updateServiceLocationDataWithBusinessLocationData,
} from 'framework/lib/WebstoreAddressService/WebstoreAddressService';
import { businessAddressSchema } from 'vendor/constants/schema/BusinessAddressSchema';
import { PriceTierSchemaWrapper } from 'vendor/components/Webstore/PriceTierSection/PriceTierSectionSchema';

const EditAboutPage = () => {
    const { formatServiceAreaForPatch, formatServiceLocationForPatch, getBusinessAddress } =
        WebstoreAddressService;
    const { webstore } = useWebstoreContext();
    const { onSubmit } = useOutletContext();
    const { appData } = useStore();

    const selectedPriceTierDefaultValue = webstore.SelectedPriceTier?.toString() ?? null;

    const vendorBusinessDetailsFormSchema = vendorBusinessDetailsFormSchemaWrapper(
        appData.categoryList,
        true
    );

    const priceTierFormSchema = PriceTierSchemaWrapper();
    const schemas = [
        vendorBusinessSummaryFormSchema,
        serviceAreaFormSchema,
        vendorBusinessDetailsFormSchema,
        businessAddressSchema,
        priceTierFormSchema,
    ];

    if (webstore.HasBeenPublished || webstore.WebstoreUrl) {
        const urlSchema = webstoreUrlSchemaWrapper(webstore);
        schemas.push(urlSchema);
    }

    if (webstore.hasCapacity) {
        schemas.push(vendorCapacityFormSchema);
    }

    const mergedSchemas = mergeSchemas(schemas);

    const businessAddress = getBusinessAddress(webstore);
    const serviceAreaFormDefaults = getFormDefaults(webstore, true);

    const formOptions = {
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        defaultValues: {
            WebstoreUrl: webstore.WebstoreUrl,
            BusinessSummary: webstore.BusinessSummary,
            WebstoreName: webstore.WebstoreName,
            // prettier-ignore
            BusinessAddress: [
                businessAddress?.address1,
                businessAddress?.address2,
                businessAddress?.city,
                businessAddress?.state,
                businessAddress?.zipCode,
            ].filter((x) => !!x) .join(', '),
            [formFieldNames.BusinessAddressStreet1]: businessAddress?.address1,
            [formFieldNames.BusinessAddressStreet2]: businessAddress?.address2,
            [formFieldNames.BusinessAddressCity]: businessAddress?.city,
            [formFieldNames.BusinessAddressState]: businessAddress?.state,
            [formFieldNames.BusinessAddressZipcode]: businessAddress?.zipCode,
            VendorCategoryName: webstore.VendorCategoryName,
            VendorCategoryId: webstore.VendorCategoryId,
            VendorSubCategoryName: webstore.VendorSubCategoryName,
            VendorSubCategoryIds: webstore.VendorSubCategoryIds,
            SelectedPriceTier: selectedPriceTierDefaultValue,
            Website: webstore.Website,
            YouTube: webstore.YouTube,
            Google: webstore.Google,
            Pinterest: webstore.Pinterest,
            Yelp: webstore.Yelp,
            Twitter: webstore.Twitter,
            Facebook: webstore.Facebook,
            TikTok: webstore.TikTok,
            Instagram: webstore.Instagram,
            SoundCloud: webstore.SoundCloud,
            Spotify: webstore.Spotify,
            Vimeo: webstore.Vimeo,
            Capacity: webstore.Capacity,
            ...serviceAreaFormDefaults,
        },
    };

    const handleSubmit = (data) => {
        if (data.ServiceAddressIsBusinessAddress) {
            updateServiceLocationDataWithBusinessLocationData(data);
        }

        formatBusinessLocationForPatch(webstore, data);
        formatServiceLocationForPatch(webstore, data);
        formatServiceAreaForPatch(webstore, data);

        data.Addresses = webstore.Addresses;

        onSubmit(data);
    };

    return (
        <PearlForm
            formName="EditAbout"
            formOptions={formOptions}
            onSubmit={(data) => handleSubmit(data)}
            validationSchema={mergedSchemas}
            formType={FormType.EDITABLE}
            StyledForm={EditAboutPageStyled}
        >
            <EditSectionLayout sectionName={WebstoreSectionTypes.ABOUT}>
                <h2>{webstore.WebstoreName}</h2>
                <BasicsEditForm displayUrlInfo={true} />
                <BusinessSummarySectionEdit />
                <PriceTierSectionEdit defaultTierValue={selectedPriceTierDefaultValue} />
                <ServiceAreaSectionEdit />
                {webstore.hasCapacity && <CapacitySectionEdit />}

                <SocialLinksSectionEdit />
            </EditSectionLayout>
        </PearlForm>
    );
};

export { EditAboutPage };
